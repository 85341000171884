import React from 'react';
import ProductTable from './ProductTable';
import './table.css'
function newmaterial1() {
    return (
        <div style={{ width: "100%" }}>
            <ProductTable />
        </div>
    )
}
export default newmaterial1