import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



  
  
  
  
export default function Review() {
 
  return (
    <React.Fragment>
    <Grid container xs={12} spacing={2}>
    <Grid item xs={12} >
    <Typography component="h6" variant="h6" align="left">
            Page Not Found
          </Typography> 
    </Grid>
    
        </Grid>
 </React.Fragment>
  );
}