import React, { useState,useEffect } from 'react';
// import Product from "../../components/Products";
import Product from "../../components/FetchproductApi";
import Snackbar from "@material-ui/core/Snackbar";
import {
  AppBar,
  Dialog,
  IconButton,  
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Grid,
  FormControl, 
  TextField
  
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    width: "90%",
    margin: theme.spacing(5),
  },
  textarea: {
    resize: "both",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  errorTable: {
    margin: 10,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  customWidth: {
    maxWidth: 420,
  },
}));

const ProductAdd = (props) => {  
  const classes = useStyles();  
  var { open, handleClose } = props;
  const [filterparams, setFilterparams] = useState({});
  const [data, setData] = useState(null);
  const [Openz1, setOpenz1] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleClick = () => {
    setOpenz1(true);
  };
  const fetchData = async () => {
    try { 
      console.log(inputValue,"inputValue")      
      if(inputValue == ""){
        setData(2); 
        setSnackMessage({
          ...snackMessage,
          message: "Enter Bar code...!!",
          severity: "error",
        }); 
        handleClick();        
      }
      else{
        setData(3);                       
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/ecommerceTransSku`, {
          inputValue: inputValue
        });  
        
        if(response.data) {
          setData(1); 
          setSnackMessage({
            ...snackMessage,
            message: "Successfully Inserted!!",
            severity: "success",
          });
          handleClick();       
        }
      }
      
      
    } catch (error) {
      console.log(error,"abchhhh")
      if (error.response && error.response.data && error.response.data.error === 'MySQL connection is not active') {
        setData(5);
        setSnackMessage({
          ...snackMessage,
          message: "Acme DB Connection error!!",
          severity: "error",
        });
        handleClick(); 
      }
      else if(error.response && error.response.data && error.response.data.error === 'Not updated record!!'){
        setData(2);
        setSnackMessage({
          ...snackMessage,
          message: "Acme DB records not updated!!",
          severity: "error",
        });
        handleClick(); 
      }
      else{
        setData(2); 
        setSnackMessage({
          ...snackMessage,
          message: "Error in insertion!!",
          severity: "error",
        }); 
        handleClick();
      }
      
    }
  };
  const handleInputChange = (e) => { 
    setData(null);   
    setInputValue(e.target.value);
  };
  const handleCloseZ = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenz1(false);
  };
  const [snackMessage, setSnackMessage] = React.useState({
    message: "",
    severity: "",
  });  
  useEffect(() => {
    // This effect will run whenever the 'data' state changes
    // Update the 'filterparams' state to trigger a re-render of the <Product /> component
    setFilterparams({}); // You may update 'filterparams' based on your requirements
  }, [data]);

  return (
    <Dialog fullScreen open={open} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            Product Add
          </Typography>
          
        </Toolbar>
      </AppBar>
      <Grid
        className={classes.content}
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth required>
            <Typography variant="h4">Enter tag No to add in ecommerce...</Typography>
            
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ padding: "9px" }}>
          <TextField          
            
            rows={15}
            fullWidth
            label={"Enter BarCode..."}
            name="sync_url"
            variant="outlined"
            onChange={handleInputChange}
            value={inputValue}
            required
            inputProps={{ className: classes.textarea }}
          />
          <Button variant="contained" color="primary" component="span" onClick={fetchData}>
                  get product info
                </Button>
        </Grid>
        {/* {(data && data == 1) && <Grid item xs={12} style={{ padding: "9px" }}>Successfully Inserted!!!</Grid>}    */}
        {/* {(data && data == 1) && <Grid item xs={12} style={{ padding: "9px" }}>Successfully Inserted!!!</Grid>}
        {(data && data == 2) && <Grid item xs={12} style={{ padding: "9px" }}>"Error in  Insertion!!!" </Grid>}  */}
        {(data && data == 3) && <Grid item xs={12} style={{ padding: "9px" }}>"Insertion in progress!!!" </Grid>}  
        {/* {(data && data == 5) && <Grid item xs={12} style={{ padding: "9px" }}>"Acme DB connection error!!!" </Grid>} */}
        <React.Fragment>
        <Snackbar open={Openz1}  onClose={handleCloseZ}>
          <Alert onClose={handleCloseZ} severity={snackMessage.severity}>
            {snackMessage.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
        {/* {(data == 3) && <div className="overall-loader">
            <div id="loading"></div>
            </div>} */}
            <Product filterparams={filterparams} />           
      </Grid>
      
    </Dialog>
  );
};

export default ProductAdd;
