import React, { useEffect, useState } from "react";
import {API_URL} from "../../config";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withApollo } from "react-apollo";
import { PRODUCTFILTERMASTER } from "../../graphql/query";

import Link from "@material-ui/core/Link";
import Product from "../../components/Products";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ProductFilter } from "../../components";
import ProductSync from "./ProductSync";
// done by kirthi..
import ProductAdd from "./ProductAdd";
import InactiveProduct from "./InactiveProduct"
import '../Productlist/customstyles.css';

export const Productlist = withRouter(
  withApollo((props) => {
    const [masters, setMasters] = useState({});
    const [filterparams, setFilterparams] = useState({});

    const [open, setOpen] = React.useState(false);
    // done by kirthi...
    const [open1, setOpen1] = React.useState(false);
    const [inactivelistopen1, setinactivelistopen1] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    // kirthi..
    const handleClickOpen1 = () => {
      setOpen1(true);
    };
    const handleClickInactiveOpen = () => {
      setinactivelistopen1(true);
    };

    const handleClose1 = () => {
      setOpen1(false);
    };
    const handleCloseinactiveList = () => {
      setinactivelistopen1(false);
    };
    const handleExportToExcel = async () => {
      try {
        // Make a GET request to the backend API endpoint that generates the Excel file
        const response = await fetch(API_URL+'/allInactiveTranskuListExcel');
        
        if (response.ok) {
          // Convert the response to a blob
          const blob = await response.blob();
  
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);
  
          // Create an anchor element for downloading the file
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Inactive_transSku_ids.xlsx'; // Specify the desired filename
  
          // Trigger a click event to download the file
          a.click();
  
          // Clean up after the download link is no longer needed
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error exporting to Excel:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error exporting to Excel:', error);
      }
    };

    function onFilter(filterobj) {
      let filtercontent = {};

      if (filterobj.product_category) {
        filtercontent["categoryname"] = filterobj.product_category.name;
        // alert(JSON.stringify(categoryname))
      }
      if (filterobj.product_type) {
        filtercontent["product_type"] = filterobj.product_type.name;
        // alert(JSON.stringify(categoryname))
      }
      setFilterparams({
        ...filterparams,
        ...filtercontent,
      });
      // fetchadminusers()
    }
    function onSearch(searchtext) {
      //  alert(searchtext)
      setFilterparams({
        ...filterparams,
        searchtext,
      });
      // fetchadminusers()
    }
    useEffect(() => {
      const query = props.client.query;
      query({
        query: PRODUCTFILTERMASTER,
        fetchPolicy: "network-only",
      })
        .then((data) => {          
          if (data) {
            let product_categories = data.data.allMasterProductCategories.nodes;
            let product_types = data.data.allMasterProductTypes.nodes;
            setMasters({
              product_categories,
              product_types,
            });
          } else {
          }
        })
        .catch((error) => {
          console.log("smbcj");
        });
    }, []);

    return (
      <Grid container spacing={2}>
        <Grid container item xs={12} sm={12} alignItems={"flex-end"}>
          <Grid fullwidth item xs={6} sm={6}>
            <Typography component="h6" variant="h6">
              Products
            </Typography>
          </Grid>

          <Grid fullwidth item xs={6} sm={6} style={{ "text-align": "right" }}>
            <Link underline="none">
              <Button variant="contained" color="primary" disabled>
                Add New Product
              </Button>
            </Link>
            <Button variant="contained" color="primary" onClick={handleClickOpen} style={{ marginLeft: 2 }}>
              Product Sync
            </Button>
            <Button variant="contained" color="primary" onClick={handleClickOpen1} style={{ marginLeft: 2 }}>
              Product Add
            </Button>
            {/* <Button variant="contained" color="primary"  onClick={handleExportToExcel} style={{ marginLeft: 2 }}>
              Inactive Product 
            </Button> */}
            <Button variant="contained" color="primary"  onClick={handleClickInactiveOpen} style={{ marginLeft: 2 }}>
              Inactive Product List 
            </Button>
          </Grid>
        </Grid>
        <ProductFilter masters={masters} onSearch={onSearch} onFilter={onFilter} />
        <Product filterparams={filterparams} />
        <ProductSync open={open} handleClose={handleClose} />
        {/* kirthi.. */}
        
        <ProductAdd open={open1} handleClose={handleClose1} />
        <InactiveProduct open={inactivelistopen1} handleClose={handleCloseinactiveList} />
        {/* <div id="loading"></div> */}
      </Grid>
    );
  })
);

export default Productlist;
