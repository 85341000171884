import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "../../config";

import axios from "axios";
import { Paper, Card, CardHeader, CardContent, Grid, Snackbar, Button, Input } from "@material-ui/core";
import { Typography, TextField } from "@material-ui/core";
import { NetworkContext } from "../../context/NetworkContext";
import "./upload.css";
import { Alert } from "@material-ui/lab";
import { IMAGEDELETE } from "../../graphql/query";
import DeleteIcon from "@material-ui/icons/Delete";
import { GRAPHQL_DEV_CLIENT } from "../../config";
import EditIcon from "@material-ui/icons/Edit";
import { useLocation } from "react-router-dom";
const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 100,
    marginTop: theme.spacing(3),
  },

  tableWrapper: {
    overflowX: "auto",
  },
  fixedTag: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      padding: 0,
    },
  },
  root: {
    marginTop: theme.spacing(2),
  },
  table: {
    width: "100%",
    // marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
  },
  gempapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "100%",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  styleFile: {
    paddingTop: "10px",
  },
  card: {
    minHeight: "150px",
    textAlign: "left",
    boxShadow: "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
  },
  formContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 30,
  },
}));

export default function Productimages(props) {
  console.log(props.prodimages, "props.prodimages")
  const classes = useStyles2();
  let image_count = 0;
  let product_id = "";
  const [success, setSuccess] = React.useState(false);
  const [Openz1, setOpenz1] = React.useState(false);  
  const [editsuccess, setEditsuccess] = React.useState(false);
  const [productimages, setProductimages] = React.useState(props.prodimages);  
  var indexZ=[];  
  const [inputValues, setInputValues] = React.useState([]);
  React.useEffect(() => {
    indexZ = productimages.map((_, index) => index + 1);
    setInputValues(indexZ);
  }, [productimages]);
  console.log(inputValues,"inputValues+++++")
  const { sendNetworkRequest } = React.useContext(NetworkContext);

  product_id = useLocation().pathname.split('/').pop()
  const handleClick = () => {
    setOpenz1(true);
  };
  React.useEffect(() => {
    setProductimages(props.prodimages);
  }, [props.prodimages]);

  productimages.forEach((imgobj) => {

    image_count = image_count + 1;
    if (imgobj.productId) {
      product_id = imgobj.productId;
    }
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseZ = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenz1(false);
  };
  const [snackMessage, setSnackMessage] = React.useState({
    message: "",
    severity: "",
  }); 
  const deleteImage = async (imageData) => {
    const url = GRAPHQL_DEV_CLIENT;
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: IMAGEDELETE,
        variables: { productimageid: imageData.id },
      }),
    };

    await fetch(url, opts)
      .then((res) => res.json())
      .then((fatchvalue) => {
        fatchvalue.statuscode = 200 && window.location.reload();
      })
      .catch(console.error);
  };
  
  const handleChange = (e, index) => {    
    const newInputValues = [...inputValues];
    newInputValues[index] = e.target.value;    
    setInputValues(newInputValues);
  };
  const deleteImage1 = async (imageData) => {
    console.log(imageData, "imageData")
    const url = GRAPHQL_DEV_CLIENT;
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: IMAGEDELETE,
        variables: { productimageid: imageData.id },
      }),
    };

    await fetch(url, opts)
      .then((res) => res.json())
      .then((fatchvalue) => {
        // fatchvalue.statuscode = 200 && window.location.reload();
        console.log("kkkk")
      })
      .catch(console.error);
  };
  const editImageNew = async (e, imageData) => {
    console.log(e, "file", imageData, "editImageNew");
    const url = GRAPHQL_DEV_CLIENT;
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: IMAGEDELETE,
        variables: { productimageid: imageData.id },
      }),
    };

    await fetch(url, opts)
      .then((res) => res.json())
      .then((fatchvalue) => {
        // after deletion
        if (fatchvalue.statuscode = 200) {
          console.log(fatchvalue.statuscode, "fatchvalue.statuscode")
          const files = e.target.files;
          console.log(files, "abcv")
          Object.keys(files).map(async (file, index) => {
            var imagecount = 1;
            if (productimages) {
              imagecount = image_count + 1;
            }
            let imagename = product_id + "-" + imagecount;
            const fileParts = files[index].type.split("/");
            const fileType = fileParts[1];

            await uploadimagetoserver(files[index], fileType, imagename, product_id, {}, false);
          });
        }
        else {
          console.log("not deleted...")
        }
      })
      .catch(console.error);
  };
  const  handlenewAssetChange1 = async (e, url) => {
    console.log(url, "url....")
    var imgURL = url.imageUrl;
    console.log(imgURL, "imgURL")
    const path = imgURL.replace("https://s3.ap-southeast-1.amazonaws.com/media.nacjewellers.com/", "")


    console.log(path, "path")
    const file = e.target.files[0];    
    try {
      // checking the file typ is image or Notification...
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const extension = file.name.split('.').pop().toLowerCase();
      
      if (allowedExtensions.includes(extension)) {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('path', path);
        // let responsecontent = await sendNetworkRequest("/updateproductimage", {}, { imageobj: imagecontent, isedit: isedit }, false);
        const response = await fetch(API_URL + "/update-image", {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          console.log('Image content updated successfully on the backend.');
          // alert("success")
          setEditsuccess(true);
          setTimeout(function () {
            window.location.reload();
          }, 2500);
          // Handle any additional actions if needed
        } else {
          console.error('Error updating image content on the backend:', response.statusText);
          alert("error")
        }
      }
      else {
        // alert("only jpg,jpeg,png files are allowed!!!")
        setSnackMessage({
          ...snackMessage,
          message: "only jpg,jpeg,png files are allowed!!!",
          severity: "error",
        }); 
        handleClick();
      }
    } catch (error) {
      console.error('Error updating image content on the backend:', error);
      // Handle the error as needed
    }
  };
  async function removeSpan() {
    console.log(productimages, "productimages");
  
    const indexZ = productimages.map((a) => a.id);
    const positionchangedvalues = [];
  
    await document.querySelectorAll(".positionchange ").forEach((el) => {
      var yt=el.value.trim();
      if(yt){
        positionchangedvalues.push(yt);        
      }      
    });
  
    console.log(positionchangedvalues, "positionchangedvalues", indexZ, "indexZ");
    console.log(positionchangedvalues.length, "positionchangedvalues", indexZ.length, "indexZ");
    //find duplicates...
    function findDuplicates(arr) {
      const frequencyCounter = {};
    
      // Count occurrences of each element
      for (const element of arr) {
        frequencyCounter[element] = (frequencyCounter[element] || 0) + 1;
      }
    
      // Filter elements with more than one occurrence
      const duplicates = Object.keys(frequencyCounter).filter(key => frequencyCounter[key] > 1);
    
      return duplicates;
    }
    const duplicates = findDuplicates(positionchangedvalues);
    //to check whether image position is right..
    const Invalidpositionchangedvalues = positionchangedvalues.filter(checkpositionchangedvalues);

    function checkpositionchangedvalues(pos) {
      return pos > positionchangedvalues.length;
    }
    console.log(Invalidpositionchangedvalues,"Invalidpositionchangedvalues")
    if(positionchangedvalues.length !== indexZ.length){      
        setSnackMessage({
          ...snackMessage,
          message: "Position for all images are manadatory!!",
          severity: "error",
        }); 
        handleClick(); 
      // alert("Position for all images are manadatory!!")
      // return console.error('Error updating image position on the backend');            
    } 
    else if(duplicates.length){      
        setSnackMessage({
          ...snackMessage,
          message: "Position for all images should be distinct!!",
          severity: "error",
        }); 
        handleClick(); 
      // setimgpositionfail2(true)
      // alert("Position for all images should be distinct!!")
      // return console.error('Error updating image position on the backend');      
    }
    else if(Invalidpositionchangedvalues.length){      
      setSnackMessage({
        ...snackMessage,
        message: "Give valid Position for all images !!",
        severity: "error",
      }); 
      handleClick();          
  }
    else{
      const requestBody = {
        positionchangedvalues: positionchangedvalues,
        indexZ: indexZ,
      };  
      // Convert the object to a JSON string
      const requestBodyString = JSON.stringify(requestBody);    
      const response = await fetch(API_URL + "/editimageposition", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestBodyString,
      });
      if (response.ok) {
        setEditsuccess(true);
        setTimeout(function () {
          window.location.reload();
        }, 2500);      
      } else {
        console.error('Error updating image position on the backend:', response.statusText);
        alert("error")
      }
    }
    
  }
  
  const handlenewAssetChange = (e) => {
    const files = e.target.files;

    Object.keys(files).map((file, index) => {
      var imagecount = 1;
      if (productimages) {
        imagecount = image_count + 1;
      }
      let imagename = product_id + "-" + imagecount;
      const fileParts = files[index].type.split("/");
      const fileType = fileParts[1];

      uploadimagetoserver(files[index], fileType, imagename, product_id, {}, false);
    });
  };

  async function uploadimagetoserver(fileobj, filetype, imagename, prodid, imagecontent, isedit) {
    console.log(fileobj, "fileobj")
    let responsedata = await sendNetworkRequest(
      "/uploadimage",
      {},
      { image: filetype, filename: imagename, product_id: prodid },
      false
    );

    var returnData = responsedata.data.returnData;
    var signedRequest = returnData.signedRequest;
    var url = returnData.url;
    var filepathname = returnData.filepath;
    filepathname = filepathname.replace("base_images", "product/" + prodid);
    var options = {
      headers: {
        "Content-Type": filetype,
        // "Access-Control-Allow-Origin": "*",
      },
    };

    if (!isedit) {
      const imageobj = {
        name: product_id + "_" + (image_count + 1),
        imagePosition: image_count + 1,
        productColor: props.color,
        productId: product_id,
        imageUrl: url,
        url: "https://s3.ap-south-1.amazonaws.com/styloribaseimages/" + filepathname,
      };
      imagecontent = imageobj;
    }

    if (!isedit) {
      var productimgs = [];
      productimages.forEach((img) => {
        productimgs.push(img);
      });
      productimgs.push(imagecontent);
    }
    console.log(signedRequest, "signedRequest", fileobj, "fileobj", options, "options")
    await axios.put(signedRequest, fileobj, options);
    let responsecontent = await sendNetworkRequest("/updateproductimage", {}, { imageobj: imagecontent, isedit: isedit }, false);
    responsecontent.statuscode === 200 && setSuccess(true);
    setTimeout(function () {
      responsecontent.statuscode === 200 && window.location.reload();
    }, 2500);
  }

  return (
    <Paper className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title={props.color && props.isdefault ? props.color + " (Default Colour)" : props.color} />
        <CardContent>

          <Grid container spacing={2} className={classes.styleFile} style={{ position: "relative" }}>
            <Button
              color="primary"
              variant="contained"
              style={{ width: "150px", height: "fit-content", position: "absolute", right: "1px", top: "-33px" }} onClick={() => removeSpan()}>
              Update Position
            </Button>

            {productimages.map((url, index) => (

              <React.Fragment key={url.id}>
                <div style={{ position: "relative",padding:'10px' }}>
                  <i
                    className="fa fa-window-edit"
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 11,
                      color: "red",
                      zIndex: "12",
                    }}
                  />

                  <Grid
                    item
                    style={{
                      width: "150px",
                      wordBreak: "break-all",
                      height: "150px",
                      padding: "8px",
                      margin: "0",
                      left:'15px',
                      // cursor: "pointer",
                      position: "relative",
                    }}
                    className="container"

                  >
                    <img
                      src={url.imageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                      alt="product images"
                    />


                  </Grid>
                  <div style={{ margin: "auto", display: "flex", gap: "2px " }}>
                    <Button variant="outlined" style={{ margin: "auto", display: "flex" }} onClick={() => deleteImage(url)}>
                      <DeleteIcon style={{ color: "grey" }} />
                    </Button>
                    <Button variant="outlined" style={{ margin: "auto", display: "flex" }} >
                      <EditIcon style={{ color: "grey" }} />
                      <input type="file" className="custom-file-input" accept="image/*" style={{ width: '100px', height: '50px' }} onInput={(e) => handlenewAssetChange1(e, url)} />
                    </Button>
                    {/* <label className="custom-file-upload"  >
                  <i
                    className="fa-solid fa-pen"
                    aria-hidden="true"
                    style={{
                      color: "pink",
                      fontSize: "45px",
                      margin: "auto",
                    }}
                  ></i>
                  <input type="file" className="custom-file-input" onChange={(e) => editImageNew(e)}></input>
                </label> */}

                  </div>
  
                  <br />
                  <input type="hidden" value={url.id} className="urlbox" inputProps={{
                    'data-imagePosition': url.imagePosition,
                    'data-imageUrl': url.imageUrl,
                    'data-ishover': url.ishover,
                    'data-productid': url.productId,
                  }} />
                  {/* <Typography style={{ textAlign: "center" }} variant="h5" className="textbox" >{" "}
                    {index + 1}{" "}
                  </Typography> */}
                  {/* <TextField style={{width:"130px",border:"1px solid gray", textAlign:"center" , paddingTop:"10px",paddingLeft:"60px"}} value={inputValues[index]} onChange={(e)=>handleChange(e,index)} key={index} id={index} className="positionchange"/>               */}
                  <input type="text" className="number_input positionchange" value={inputValues[index]} onChange={(e)=>handleChange(e,index)} key={index} id={index}/>
                  








                  <Typography style={{ textAlign: "center" }} variant="h6">
                    {" "}
                    {url.ishover ? "hover" : ""}{" "}
                  </Typography>
                </div>
                {/* ) : null} */}
              </React.Fragment>
            ))}
            <Grid
              item
              style={{
                width: "150px",
                wordBreak: "break-all",
                height: "150px",
                padding: "8px",
                margin: "0",
                cursor: "pointer",
                textAlign: "center",
                position: "relative",
              }}
              className="container"
            >
              {
                <label className="custom-file-upload" style={{ display: "flex" }}>
                  <i
                    className="fa fa-plus"
                    aria-hidden="true"
                    style={{
                      color: "rgba(60,64,67,.15)",
                      fontSize: "45px",
                      margin: "auto",
                    }}
                  ></i>
                  <input type="file" className="custom-file-input" onChange={(e) => handlenewAssetChange(e)}></input>
                </label>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose}>Image Upload Successfully...</Alert>
      </Snackbar>
      <Snackbar open={editsuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose}>Image Updated Successfully...</Alert>
      </Snackbar>
      <Snackbar open={Openz1}  onClose={handleCloseZ}>
          <Alert onClose={handleCloseZ} severity={snackMessage.severity}>
            {snackMessage.message}
          </Alert>
        </Snackbar>

      
    </Paper>
  );
}
