import { Button, FormControlLabel, Switch } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {API_URL} from "../../config";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import VisibityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withApollo } from "react-apollo";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { NetworkContext } from "../../context/NetworkContext";
import { PRODUCTLISTSTATUSEDIT } from "../../graphql/query";
// import SyncIcon from '@mui/icons-material/Sync';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const columns = [
  // { id: "sku_id", label: "tag id" },
  // { id: "product_id", label: "product id" },
  // { id: "product_name", label: "product name" },
  // { id: "product_type", label: "product type" },
  // { id: "vendor_code", label: "vendor code" },
  // { id: "product_category", label: "product category" },
  // { id: "isactive", label: "active" },
  // { id: "createdAt", label: "Created on" },
  {id:"id",label:"Id"},
  {id:"createdAt",label:"Fetch Date"},
  {id:"updatedAt",label:"Sync Date"},
  {id:"status",label:"Status"},
  {id:"count",label:"Product Count"},
  {id:"action",label:"Action"}
];

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));
function createData(name, calories, fat) {
  return { name, calories, fat };
}
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list"></IconButton>
          </Tooltip>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const AddContact = (props) => {
  let history = useHistory();
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(0);
  const [offsetValue, setOffsetValue] = React.useState(0);
  const [productlists, setProductlists] = React.useState([]);
  const [allproductlists, setAllProductlists] = React.useState([]);
  const [mastercategories, setMastercategories] = React.useState([]);
  const [masterproducttypes, setMasterproducttypes] = React.useState([]);
  const { sendNetworkRequest } = React.useContext(NetworkContext);
  const [searchtext, setSearchtext] = React.useState("");
  const [inactiveresponse, setinactiveresponse] = React.useState("");
  // const [dialoghandleClose,setdialoghandleClose]=React.useState(false);
  const [Dialogopen,setDialogopen]=React.useState(false);
  const [rowsdialog, setRowsdialog] = React.useState([]);
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.contactlist.length - page * rowsPerPage);
  function handleChangePage(event, newPage) {
    setPage(newPage);
    setOffsetValue(newPage * rowsPerPage);
    getproductlist("", "", "", "", newPage);
  }

  
  useEffect(() => {
    getproductlist("", "", "", "", "", order, orderBy);
  }, []);
  
  useEffect(() => {
    getproductlist(
      props.filterparams.searchtext,
      props.filterparams.categoryname,
      props.filterparams.product_type,
      "",
      "",
      order,
      orderBy
    );
  }, [props.filterparams]);
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getproductlist("", "", "", event.target.value, "");
  }
  async function showproductdetails(prod_id) {
    let bodycontent = {
      productid: prod_id,
    };

    let response = await sendNetworkRequest("/inactivehistory", {}, bodycontent);
    console.log(response);
    //setProductlists(response.products.rows)
    window.open(response.url, "_blank");
  }
  function ProductEdit(id) {
    // localStorage.setItem('productEditId',id);
    history.push(`product_attributes/${id}`);
  }
  const dialoghandleClose = () => {
    setDialogopen(false);
  }; 
  async function ShowInactiveInModal(id) {
    // alert(id);
      
    try {
      const response = await fetch(API_URL + '/allInactivetranskulistWithIdModalshow?id=' + id);
      console.log(response,"kkkk")  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } 
      const data = await response.json(); 
      console.log(data,"databbbb") 
      if (Array.isArray(data.data)) {
        setRowsdialog(data.data);
        setDialogopen(true);
      } else {
        console.error('Invalid data format from the backend:', data);
      }        
      // setRowsdialog(data);
      // setDialogopen(true);           
      
    } catch (error) {
      console.error('Error fetching data from backend:', error.message);
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getproductlist("", "", "", "", "", isAsc ? "desc" : "asc", property);
  };
  function searchproduct(searchtext, productcategory, producttype) {
    let products = allproductlists.filter((l) => {
      return (
        l.productId.toLowerCase().match(searchtext.toLowerCase()) ||
        l.productName.toLowerCase().match(searchtext.toLowerCase())
      );
    });
    setProductlists(products);
  }
  async function getproductlist(
    searchtext,
    productcategory,
    producttype,
    pagesize,
    offsetvalue,
    sort,
    orderby
  ) {
    let bodydata = {
      size: pagesize ? pagesize : rowsPerPage,
      offset: offsetValue,
      searchtext: searchtext,
      productcategory: productcategory,
      producttype: producttype,
      order: sort ? sort : order,
      orderby: orderby ? orderby : orderBy,
    };
    // let response = await sendNetworkRequest("/getproductlist", {}, bodydata);
    let response = await sendNetworkRequest("/inactivehistory", {}, bodydata);
    

    console.log(response,"aaaa");
    setProductlists(response.products.rows);
    setPageCount(response.products.count);
  }
  function applyfilter(searchtext, categoryname, typename) {
    getproductlist(searchtext, categoryname, typename);
  }
  
  const handleToInactive = async (id) => {    
    try{
      const response = await fetch(API_URL+'/makeInactive?id='+id);
    if(response){
      alert("successsfully products inactivated!!")           
      getproductlist("", "", "", "", "", order, orderBy);
    }
    else{
      alert("failed to inactive products!!")
    }
    }
    catch(error){
      console.error('Error to make inactive:', error);
      alert("error")   
      window.location.reload();   
    }
    
  }
  const handleExportToExcel = async (id) => {
    try {     
      // Make a GET request to the backend API endpoint that generates the Excel file
      const response = await fetch(API_URL+'/allInactivetranskulistExcelWithId?id='+id);
      
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element for downloading the file
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Inactive_transSku_ids.xlsx'; // Specify the desired filename

        // Trigger a click event to download the file
        a.click();

        // Clean up after the download link is no longer needed
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Error exporting to Excel:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };
  // function productItemStatusChange(id,isactive){
  // let variable = {
  //   "productId": id
  // };
  // let status = isactive ? variable.isActive = false :variable.isActive = true;
  
  // const [productItemStatusChange,{ data }] = useMutation(PRODUCTLISTSTATUSEDIT);
  // }
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          border={1}
          borderColor={"#ddd"}
          size="small"
          stickyHeader
        >
          {/* <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead> */}
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* <Query
              query={PRODUCTLIST(true,"Bangles")}
              onCompleted={data => setPageCount( data.allProductLists.totalCount )}
              variables={{ "Veiw": rowsPerPage, "Offset": offsetValue}}>
              {
                  ({ data, loading, error, refetch }) => {
                  
                      if (loading) {
                          // return <Loader />
                      }
                      if (error) {
                        return <div>{error}</div>
                          // return false
                      }
                      if (data) { 
                           return <> */}
            {stableSort(productlists, getComparator(order, orderBy)).map(
              (row, index) => (
                <TableRow key={row.id}>
                  
                  <TableCell align="left">{row.id}</TableCell> 
                  <TableCell align="left">
                  <Moment format="DD MMM YYYY hh:mm a">
                      {row.createdAt}
                    </Moment>
                  </TableCell>  
                  <TableCell align="left">
                  <Moment format="DD MMM YYYY hh:mm a">
                      {row.updatedAt}
                    </Moment>
                  </TableCell>               
                                  
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.count}</TableCell> 
                  
                  <TableCell align={"left"} padding="none">
                      
                      {/* for modal view */}
                      <IconButton
                      color="inherit"
                      onClick={() => {ShowInactiveInModal(row.id) ; }}
                    >
                      <Tooltip title="View Inactive Products" >
                        {/* <EditIcon /> */}
                        <VisibityIcon />
                      </Tooltip>
                    </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => handleToInactive(row.id)}                         
                      >
                        <Tooltip title="Make Inactive">
                          {/* <AddCircleIcon /> */}
                          <CloudUploadIcon/>                          
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  
                </TableRow>
              )
            )}
            {/* </> */}
            {/* }
                      else{
                      return <div>{"Fetch Products"}</div>
                     
                 } } }
                </Query>  */}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}  */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 200, 500]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
      <Dialog open={Dialogopen} onClose={dialoghandleClose} maxWidth="md" fullWidth>
        <DialogTitle>Sold Products</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <TableContainer component={Paper}> */}
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>Sku Id</TableCell>                    
                    <TableCell>ProductId</TableCell>
                    <TableCell>Product Url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsdialog.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.generated_sku}</TableCell>
                      <TableCell>{row.product_id}</TableCell>
                      <TableCell><a  href={row.sku_url} target="_blank" rel="noopener noreferrer">{row.sku_url}</a></TableCell>
                      
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            {/* </TableContainer> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialoghandleClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
    
  );
};
export default withApollo(AddContact);
